<template>
	<div class="data-list-container main-list-view ml-6">
		<!-- title name -->
		<div class="mb-4 mt-6 ml-6" style="font-size: 28px!important">{{$t('My Events')}}</div>
		<p class="ml-6">{{ $t('Manage all of your event, click thumbnail to see details') }}</p>
		<event-modal
			:isModalActive="addNewDataModal"
			:data="modalData"
			@closeModal="toggleDataModal"
			@add="insert"
		/>
		<vs-table
			class="mt-6"
			ref="table"
			search
			sst
			:pagination="false"
			:data="events"
			:max-items="limit"
			:total="totalEvents"
			v-model="selected"
			@search="onSearch"
			@sort="onSort"
			@change-page="changePage">
			<div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
				<!-- ADD NEW -->
				<div
					class="btn-add-new px-4 py-25 mr-4 vs-button vs-button-success vs-button-filled rounded-lg cursor-pointer flex items-center justify-center"
					@click="addNewData" v-if="isSuperAdmin || isClient">
					<feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
					<span class="ml-2">{{ $t('New Event') }}</span>
				</div>

				<!-- ITEMS PER PAGE -->
				<vs-dropdown
					class="cursor-pointer mb-4 mr-4 items-per-page-handler"
					vs-trigger-click>
					<div
						class="px-4 py-25 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
						<span class="mr-2">
							{{ page * limit - (limit - 1) }} - {{ events.length - page * limit > 0 ? page * limit : events.length }} of {{ totalEvents }}
						</span>
						<feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
					</div>
					<!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
					<vs-dropdown-menu>
						<vs-dropdown-item @click="changeLimit(10)"><span>10</span></vs-dropdown-item>
						<vs-dropdown-item @click="changeLimit(25)"><span>25</span></vs-dropdown-item>
						<vs-dropdown-item @click="changeLimit(50)"><span>50</span></vs-dropdown-item>
						<vs-dropdown-item @click="changeLimit(100)"><span>100</span></vs-dropdown-item>
						<vs-dropdown-item @click="changeLimit(200)"><span>200</span></vs-dropdown-item>
					</vs-dropdown-menu>
				</vs-dropdown>
			</div>
			<template slot-scope="{data}">
				<vs-col type="flex" vs-justify="start" vs-align="start" :class="listClass" :data="item" :key="indexitem" v-for="(item, indexitem) in data">
					<vs-card actionable class="cardx-large">
						<div slot="media">
							<a @click="openDetails(item)" v-if="item.feature_image">
								<img :src="item.feature_image" alt="img" class="responsive card-img-top event-cover-image" />
							</a>
							<div class="thumbnail-event" @click="openDetails(item)" v-if="!item.feature_image">
								<div class="initial-event">{{ generateInitial(item) }}</div>
								<!-- <img src="@/assets/images/logo/default-galleries.png" alt="Galleries" class="responsive card-img-top event-cover-image" /> -->
							</div>
						</div>
						<vs-dropdown class="dd-actions cursor-pointer event-menu" vs-trigger-click v-if="isOwner(item)">
							<div>
								<feather-icon icon="MoreVerticalIcon" svgClasses="h-5 w-5" />
							</div>
							<vs-dropdown-menu>
								<!-- view -->
								<vs-dropdown-item>
									<div class="flex items-center" @click="openDetails(item)">
										<span>{{ $t('View') }}</span>
									</div>
								</vs-dropdown-item>
								<!-- edit -->
								<vs-dropdown-item>
									<div class="flex items-center" @click="editData(item)">
										<span>{{ $t('Edit') }}</span>
									</div>
								</vs-dropdown-item>
								<!-- delete -->
								<vs-dropdown-item @click="dialogImport = true">
									<div class="flex items-center" @click="deleteData(item)">
										<span>{{ $t('Delete') }}</span>
									</div>
								</vs-dropdown-item>
							</vs-dropdown-menu>
						</vs-dropdown>
						<a @click="openDetails(item)">
							<div class="item-name truncate">{{ item.name }}</div>
						</a>
						<p class="item-date truncate mt-3">{{ dateFormat(item.start_time) }} - {{ dateFormat(item.end_time) }}</p>
						<div class="flex justify-between flex-wrap">
						</div>
					</vs-card>
				</vs-col>
			</template>
		</vs-table>
		<!-- Pagination -->
		<vs-pagination
			class="mt-3"
			:total="totalPages"
			:max-items="limit"
			:size-array="totalEvents"
			v-model="page">
		</vs-pagination>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import eventsApi from '@/api/event';

import {
  duplicateVar,
  delay,
  getAxiosErrorMessage,
  dateFormat,
} from '@/lib/helper';
import EventModal from '@/components/EventModal.vue';

export default {
  name: 'Events',
  components: {
    EventModal,
  },
  data() {
    return {
      keyword: '',
      events: [],
      isFetching: false,
      isAll: false,
      page: 1,
      limit: 16,
      orderBy: 'created_at',
      sortBy: 'desc',
      totalPages: 0,
      totalEvents: 0,
      selected: [],
      selectedItem: null,
      isFormVisible: false,
      isDeleteVisible: false,
      isDeleting: false,
      isMounted: false,
      addNewDataModal: false,
      modalData: {},
      windowWidth: window.innerWidth,

    };
  },
  methods: {
    dateFormat(date) {
      return dateFormat(date, 'DD. MM. YYYY HH:mm');
    },
    fetch(reset) {
      this.$vs.loading();
      this.isFetching = true;
      if (reset) {
        this.page = 1;
        this.events = [];
      }
      const keyword = this.keyword;
      const params = {
        page: this.page,
        limit: this.limit,
        order_by: this.orderBy,
        sort_by: this.sortBy,
        is_my_event: 1,
      };
      if (keyword !== '') {
        params.keyword = keyword;
      }
      const callback = (response) => {
        const events = response.data;
        const lastPage = response.lastPage;
        this.events = events;
        this.isAll = events.length === 0 || events.length < this.limit;
        // this.page++;
        if (lastPage) {
          this.totalPages = lastPage;
        }
        this.totalEvents = response.total;
        this.isFetching = false;
        this.$vs.loading.close();
      };
      const errorCallback = () => {
        this.isFetching = false;
        this.$vs.loading.close();
      };
      eventsApi.getList(params, callback, errorCallback);
    },
    addNewData() {
      this.modalData = {};
      this.toggleDataModal(true);
    },
    deleteData(item) {
      this.selectedItem = duplicateVar(item);
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm Delete'),
        text: `${this.$t('Are you sure want to delete')} "${item.name}"?`,
        accept: this.deleteRecord,
        acceptText: this.$t('Delete'),
      });
    },
    bulkDelete() {
      const total = this.selected.length;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm Delete'),
        text: `${this.$t('Are you sure want to delete')} ${total} ${this.$t('Events')}?`,
        accept: this.deleteRecords,
        acceptText: this.$t('Delete'),
      });
    },
    editData(data) {
      this.selectedItem = duplicateVar(data);
      this.modalData = duplicateVar(data);
      this.toggleDataModal(true);
    },
    toggleDataModal(val = false) {
      this.addNewDataModal = val;
    },
    onSearch(keyword) {
      delay(() => {
        this.keyword = keyword ? keyword.trim() : '';
        this.fetch(true);
      }, 1000);
    },
    onSort(sortKey, sortType) {
      this.orderBy = sortKey;
      this.sortBy = sortType;
      if (this.orderBy && this.sortBy) this.fetch(true);
    },
    deleteRecord() {
      this.$vs.loading();
      const notifTitle = this.$t('Event');
      const item = this.selectedItem;
      const callback = (response) => {
        this.remove(item.id);
        const message = response.message;
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.$vs.loading.close();
      };
      eventsApi.delete(item.id, callback, errorCallback);
    },
    deleteRecords() {
      const notifTitle = this.$t('Event');
      const ids = this.selected.map(({ id }) => id);
      const callback = (response) => {
        ids.forEach((id) => {
          this.remove(id);
        });
        const message = response.message;
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      eventsApi.bulkDelete(ids, callback, errorCallback);
    },
    insert(item) {
      const index = this.events.findIndex(({ id }) => id === item.id);
      if (index === -1) {
        this.events.unshift(item);
        this.totalEvents++;
      } else {
        this.$set(this.events, index, item);
      }
    },
    remove(itemId) {
      const index = this.events.findIndex(({ id }) => id === itemId);
      if (index !== -1) {
        this.events.splice(index, 1);
        this.totalEvents--;
      }
    },
    changeLimit(value) {
      this.limit = value;
      this.fetch(true);
    },
    changePage(page) {
      this.page = page;
    },
    onSelectAll(event) {
      const elem = event.target;
      const isCheckbox = elem.classList.contains('vs-checkbox--input');
      const isCheckAll = !!elem.closest('.vs-table--thead');
      if (isCheckbox && isCheckAll) {
        const isChecked = elem.checked;
        if (isChecked) {
          this.selected = this.events;
        } else {
          this.selected = [];
        }
      }
    },
    isOwner(item) {
      const isOwner = this.user && this.user.id === item.user_id;
      return isOwner;
    },
    openDetails(item) {
      if (this.isOwner(item)) this.$router.push({ name: 'EventDetails', params: { slug: item.slug } });
      else this.$router.push({ name: 'EventLive', params: { eventSlug: item.slug } });
    },
    generateInitial(event) {
      const words = event.name ? event.name.split(' ') : 'AN';
      const initial = words.length > 1 ? `${words[0][0]}${words[1][0]}` : words[0][0];
      return initial;
    },
    // onUploadItems(event) {
    // 	const files = event.target.files;
    // 	if (files.length > 0) {
    // 		const file = files[0];
    // 	}
    // },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
    isSuperAdmin() {
      return this.user && this.user.role === 'super_admin';
    },
    isClient() {
      return this.user && this.user.role === 'client';
    },
    isModerator() {
      return this.user && this.user.role === 'moderator';
    },
    listClass() {
      const myClass = this.windowWidth > 1670 ? 'xs:w-1/1 sm:w-1/2 lg:w-1/4 pr-8' : 'xs:w-1/1 sm:w-1/2 lg:w-1/3 pr-8';
      return myClass;
    },
  },
  created() {
    this.fetch();
    document.addEventListener('click', this.onSelectAll);
  },
  destroyed() {
    document.removeEventListener('click', this.onSelectAll);
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  watch: {
    page() {
      this.fetch();
    },
  },
};
</script>

<style lang="scss" scoped>
.cardx-md {
	position: relative;
	min-width: 260px;
	max-width: 312px;
}
.cardx-large {
	position: relative;
	min-width: 312px;
}
.card-img-top {
	border-top-left-radius: .5rem;
	border-top-right-radius: .5rem;
	max-height: 210px;
	width: 100%;
	height: 100%;
	object-fit: cover;
	min-height: 175px;
    max-height: 175px;
}
.item-name {
	font-size: 14px;
	color: #000;
}
.item-date {
	color: #b1b1b1;
}
.event-menu {
	position: absolute;
	top: 10px;
	right: 10px;
}
.thumbnail-event{
	position: relative;
	min-height: 175px;
	max-height: 175px;
	text-align: center;
	padding: 2em;
	.initial-event {
		font-size: 3.5em;
		padding: 24px;
		font-weight: bold;
		max-width: 120px;
		margin: 0 auto;
		border-radius: 50%;
		background-color: #00a1ed;
		color: #fff;
	}
}
.wrapper-event{
	overflow-x: hidden!important;
}
</style>
